import React from "react";

const NotFoundPage = () => (
  <div align="center">
    <table border="0">
      <tr>
        <td><img src="/404-dog.jpg"></img></td>
        <td>
          <table border="0">
            <tr>
              <td>Didn&#39;t find the page!? Where&#39;s the page?!</td>
            </tr>
            <tr>
              <td>Go back human!</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
);

export default NotFoundPage;
